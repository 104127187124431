'use client';

import React from 'react';

import { ErrorHandler } from '@arpia-pt-frontends/ui-web/errorHandler';

type ErrorProps = {
  error: Error;
  reset: () => void;
};

const Error: React.FC<ErrorProps> = ({ error, reset }) => {
  return <ErrorHandler error={error} reset={reset} />;
};

export default Error;
