'use client';

import React from 'react';

import { useTranslation } from '@arpia-pt-frontends/lib/services/i18n';
import { Center, Container, VStack } from '@arpia-pt-frontends/styled-system/jsx';
import { Button } from '@arpia-pt-frontends/ui/button';
import { LayoutBoxSmaller, LayoutGrid } from '@arpia-pt-frontends/ui/layout';
import { Text } from '@arpia-pt-frontends/ui/text';

interface ErrorHandlerProps {
  error: Error;
  reset?: () => void;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({ error, reset, ...props }) => {
  const { t } = useTranslation();

  return (
    <Container {...props}>
      <LayoutGrid>
        <LayoutBoxSmaller>
          <Center>
            <VStack gap={6}>
              <Text variant="h2">{error.message || t('error.fallback.message')}</Text>
              <Text variant="block">{t('error.fallback.text')}</Text>
              <div>
                <Button size="small" icon="arrow-right" onClick={reset}>
                  {t('error.btn.tryAgain')}
                </Button>
              </div>
            </VStack>
          </Center>
        </LayoutBoxSmaller>
      </LayoutGrid>
    </Container>
  );
};

export { ErrorHandler };
export type { ErrorHandlerProps };
